<template>
  <div class="center_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      :label-position="labelPosition"
      class="demo-ruleForm"
    >
      <el-form-item label="存证编号:" prop="noid">
        <el-input
          placeholder="请输入存证编号"
          v-model="ruleForm.noid"
          class="numInput"
        ></el-input>
      </el-form-item>

      <el-form-item label="哈希值:" prop="contentText" class="hashStart">
        <el-radio-group v-model="ruleForm.filetype" @change="changeRadio">
          <el-radio :label="'FILE'">文件上传</el-radio>
          <el-radio :label="'TEXT'">手动输入</el-radio>
        </el-radio-group>

        <!-- :on-success="fileSuccess" -->
        <el-upload
          v-if="ruleForm.filetype === 'FILE'"
          :file-list="fileNow"
          class="upload-demo"
          drag
          action="#"
          :http-request="fileUpload"
          accept=".zip, .rar, .doc, .txt, .xls, .xlsx, .jpg, .jpeg, .png, .mp4, .mp3, .m3u4,image/*"
          :before-upload="beforeUpload"
          :limit="1"
        >
          <div class="maxWidth">
            <img
              class="img_icon"
              src="@/assets/img/verification/upload.png"
              alt
            />
            <div class="el-upload__text">
              拖动您的哈希值文件到这里或
              <em>点击上传</em>
              <br />支持的文件类型：文件/图片/音视频
            </div>
          </div>

          <img
            class="minScreen"
            src="@/assets/img/verification/upload.svg"
            alt=""
          />
        </el-upload>

        <div class="minScreen textDom" v-if="ruleForm.filetype === 'FILE'">
          支持的文件类型：文件/图片/音视频
        </div>

        <el-input
          v-model="ruleForm.contentText"
          v-show="ruleForm.filetype === 'TEXT'"
          placeholder="请输入哈希"
          type="textarea"
          class="fileHash"
        ></el-input>
      </el-form-item>

      <el-form-item label="验证码:" prop="seral_num" class="fatherPosition">
        <el-input
          placeholder="请输入4位验证码"
          v-model="ruleForm.seral_num"
          class="seral_num"
        ></el-input>
        <el-tooltip
          class="item"
          effect="dark"
          content="点击刷新"
          placement="top"
        >
          <Verifi_canvas @getImgCode="getImgCode" ref="verify"></Verifi_canvas>
        </el-tooltip>
      </el-form-item>
      <el-form-item class="verifyBtn">
        <el-button type="primary" @click="onSubmit" class="btnSub"
          >验证</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import { checkEvidence, verifyShare } from "@/network/verification.js";
import Verifi_canvas from "./Verifi_canvas";
export default {
  components: {
    Verifi_canvas,
  },
  props: {
    labelPosition: {
      type: String,
      default: () => "left",
    },
  },
  data() {
    let checkSeral_num = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("验证码不能为空"));
      }
      if (value.toUpperCase() !== this.imgCode) {
        return callback(new Error("请输入正确的验证码"));
      } else {
        callback();
      }
    };
    return {
      fileNow: [],
      ruleForm: {
        filetype: "FILE",
        noid: "", //存证编号 HX2021071417055944483592
        contentText: "", //哈希值 bf192dc9a362ce0e70060cc5506dea18b4d17655fd340eea1946eb8869a2caf3
      },
      rules: {
        noid: [
          { required: true, message: "请填写证书编号", trigger: "blur" },
          {
            min: 24,
            max: 24,
            message: "请输入24位的存证编号",
            trigger: "blur",
          },
        ],
        seral_num: [
          { validator: checkSeral_num, trigger: "submit" },
          { required: true, message: "请填写验证码", trigger: "blur" },
        ],
      },
      // 请求成功数据
      successData: {},
    };
  },
  methods: {
    // 点击验证 提交表格数据 发送请求
    async onSubmit() {
      //手动二次校验表格
      await this.$refs.ruleForm.validate();
      let comName = "fail_ver";
      const self = this;
      // 发送请求
      const formData = new FormData();
      // 遍历添加formData请求参数
      for (let item in this.ruleForm) {
        formData.append(item, this.ruleForm[item]);
      }

      if (this.ruleForm.filetype === "FILE") {
        console.log(this.fileNow, "文件列表");
        formData.append("contentFile", this.fileNow[0]);
      }
      axios({
        method: "POST",
        // url: "http://scaniov.com/hxapi/api/account/check-evidence", //正式服地址
        url: "http://183.3.158.22:8599/api/account/check-evidence", //测试服地址
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((res) => {
        // const { data } = await verifyFileHash(this.ruleForm);
        // 根据请求结果更改组件名显示不同页面;
        if (res.data.message == "验证通过") {
          comName = "success_ver";
          self.successData = res.data.data;
          self.successData.noid = self.ruleForm.noid;
          const params = { comName, successData: self.successData };
          self.$emit("comChange", params);
        } else {
          const params = { comName, successData: self.successData };
          // self.$message.error(res.data.message);
          self.$emit("comChange", params);
        }
      });
    },
    // 获取图片验证码
    getImgCode(code) {
      this.imgCode = code;
      // this.ruleForm.seral_num = code;
    },
    // 切换哈希传值方式
    changeRadio() {
      // 清空表单值
      this.ruleForm.contentText = "";
      // 清空校验效果
      this.$refs.ruleForm.clearValidate();
      // 切换验证码
      this.$refs.verify.handleDraw();
    },
    fileUpload(res) {
      console.log(res.file.size, "shangchaun");

      console.log(this.fileNow, "shangchaun文件");
    },
    // // 文件上传成功触发
    // fileSuccess(response) {
    //   console.log("上传成功！");
    //   this.$message.success("上传成功！");
    //   const { filehash } = response.data;
    //   this.ruleForm.contentText = filehash;
    //   // 清空校验效果
    //   this.$refs.ruleForm.clearValidate();
    // },
    // 上传前的校验
    beforeUpload(file) {
      console.log("经过上传校验");
      // 文件大小校验
      const maxFileSize = 200 * 1024 * 1024;
      if (file.size > maxFileSize) {
        this.$message.warning(
          "上传的文件不能超过" + maxFileSize / (1024 * 1024) + "m"
        );
        return false;
      }
      // 上传的文件传值
      this.fileNow[0] = file;
    },
    // 获取路由参数，实现二维码扫码验证，分享存证验证
    async checkRouteParams() {
      console.log(this.$route, "路由参数");
      const { hash, noid, type, code } = this.$route.query;
      // type 1 证书扫码，发送可信存证验证请求
      if (type == 1 && noid && hash) {
        const { data: res } = await checkEvidence({
          noid,
          contentText: hash,
          filetype: "TEXT",
        });
        console.log(res.code);
        // 验证通过
        if (res.code === 200) {
          this.$emit("comChange", {
            comName: "success_ver",
            successData: res.data,
          });
          // 验证失败
        } else {
          this.$emit("comChange", { comName: "fail_ver" });
        }
      }
      // type 2 分享查询
      if (type == 2 && code) {
        const { data: res } = await verifyShare({ noId: noid, code });
        console.log(res);
        // 验证通过
        if (res.code === 200) {
          this.$emit("comChange", {
            comName: "ShareSuccess",
            successData: res.data,
          });
          // 验证不通过
        } else {
          this.$emit("comChange", { comName: "fail_ver", isShare: true });
        }
      }
      // 清空参数
      const path = this.$route.path;
      this.$router.push(path);
    },
  },

  mounted() {
    // 获取路由参数，实现二维码扫码验证，分享存证验证
    this.checkRouteParams();
  },
};
</script>

<style lang="less" scoped>
// 通用样式
.fatherPosition {
  position: relative;
  /deep/ .img-verify {
    position: absolute;
    top: 0px;
    left: 180px;
  }
}
.seral_num {
  width: 155px;
}
// 768px

.minScreen {
  display: none;
}

.textDom {
  line-height: 20px;
}

.verifyBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  .btnSub {
    margin-top: 0.8rem;
  }
}

.el-form-item__label {
  margin-right: 10px;
}
.el-form-item__content {
  padding-left: 10px;
}

/deep/.el-radio__inner {
  width: 20px;
  height: 20px;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-color: #3d6df1;
  &.el-radio__inner::after {
    width: 10px;
    height: 10px;
    border-color: #3d6df1;
    background-color: #3d6df1;
  }
}

/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #000;
}

/deep/.hashStart {
  .el-form-item__label::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .img_icon {
    margin-top: 50px;
  }
}

// 占位符字体颜色 兼容处理
/deep/input::-webkit-input-placeholder,
/deep/textarea::-webkit-input-placeholder {
  color: #979797;
}
/deep/input:-moz-placeholder,
/deep/textarea:-moz-placeholder {
  color: #979797;
}

/deep/input:-ms-input-placeholder,
/deep/textarea:-ms-input-placeholder {
  color: #979797;
}

// 768px以上样式(768px:ipad屏幕宽)
@media screen and (min-width: 768px) {
  .demo-ruleForm {
    width: 710px;
    margin-top: 50px;
    /deep/ .el-upload-dragger {
      width: 510px;
      height: 210px;
      &:hover {
        border: 1px dashed #3d6df1;
      }
    }
    /deep/.el-upload-dragger .el-upload__text em {
      color: #3d6df1;
    }
  }
  .numInput {
    width: 510px;
    .el-input__inner {
      color: #000;
    }
  }

  /deep/.fileHash {
    .el-textarea__inner {
      width: 510px;
      height: 80px;
    }
  }
}
// 520px-768px样式
@media screen and (max-width: 768px) and(min-width:520px) {
  .center_box {
    /deep/ .el-upload-dragger {
      height: 100%;
    }
  }
}

// 小屏幕样式 小于520px
@media screen and(max-width:520px) {
  .maxWidth {
    display: none;
  }

  .minScreen {
    display: block;
  }

  /deep/ .el-upload-dragger {
    border: 1px solid #dcdcdc;
    background-color: #f5f5f5;
    width: 90vw;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-form-item {
    margin-bottom: 5px;
  }

  .verifyBtn {
    margin-top: 0.75rem;
  }

  /deep/ .el-form--label-top .el-form-item__label {
    padding: 0;
  }
}
</style>