<template>
  <div class="img-verify">
    <canvas
      ref="verify"
      :width="width"
      :height="height"
      @click="handleDraw"
    ></canvas>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      pool: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串，验证码出现的字符
      width: 120,
      height: 40,
      imgCode: "", //验证码
    };
  },
  mounted() {
    // 绘制图片验证码 将验证码传给父组件
    this.$emit("getImgCode", this.draw());
  },
  methods: {
    // 点击图片重新绘制
    handleDraw() {
      this.$emit("getImgCode", this.draw());
    },
    // 1.随机数
    randomNum(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    },
    // 2.随机颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max);
      const g = this.randomNum(min, max);
      const b = this.randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    },
    // 绘制图片
    draw() {
      // 3.填充背景颜色，背景颜色要浅一点
      const ctx = this.$refs.verify.getContext("2d");
      // 填充颜色
      ctx.fillStyle = this.randomColor(180, 230);
      // 填充的位置
      ctx.fillRect(0, 0, this.width, this.height);
      // 定义paramText
      let imgCode = "";
      // 4.随机产生字符串，并且随机旋转
      for (let i = 0; i < 4; i++) {
        // 随机的四个字
        const text = this.pool[this.randomNum(0, this.pool.length)];
        imgCode += text;
        // 随机的字体大小
        const fontSize = this.randomNum(18, 40);
        // 字体随机的旋转角度
        const deg = this.randomNum(-30, 30);

        ctx.font = fontSize + "px Simhei";
        ctx.textBaseline = "top";
        ctx.fillStyle = this.randomColor(80, 150);

        ctx.save();
        ctx.translate(30 * i + 15, 15);
        ctx.rotate((deg * Math.PI) / 180);
        // fillText() 方法在画布上绘制填色的文本。文本的默认颜色是黑色。
        // 请使用 font 属性来定义字体和字号，并使用 fillStyle 属性以另一种颜色/渐变来渲染文本。
        // context.fillText(text,x,y,maxWidth);
        ctx.fillText(text, -15 + 5, -15);
        ctx.restore();
      }
      // 5.随机产生5条干扰线,干扰线的颜色要浅一点
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.lineTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.strokeStyle = this.randomColor(180, 230);
        ctx.closePath();
        ctx.stroke();
      }
      // 6.随机产生40个干扰的小点
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height),
          1,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fillStyle = this.randomColor(150, 200);
        ctx.fill();
      }
      return imgCode;
    },
  },
};
</script>
<style type="text/css">
.img-verify canvas {
  cursor: pointer;
  border-radius: 3px;
}
</style>